<template>
	<div class="address_box">
		<div class="mask"></div>
		<div class="address_main">
			<div class="address_mai_head Between">
				<div class="title">{{ title }}</div>
				<i class="el-icon-close display pointer" @click="addBtn('取消')"></i>
			</div>
			<div class="formData_box">
				<el-form
					:model="ruleForm"
					:rules="rules"
					ref="ruleForm"
					label-width="100px"
					class="demo-ruleForm"
				>
					<el-form-item label="收货人：" prop="userName">
						<el-input
							v-model="ruleForm.userName"
							placeholder="请输入收货人姓名"
						></el-input>
					</el-form-item>
					<el-form-item label="手机号码：" prop="userPhone">
						<el-input
							maxlength="11"
							v-model="ruleForm.userPhone"
							placeholder="请输入手机号码"
						></el-input>
					</el-form-item>
					<el-form-item label="所在地址：" prop="province">
						<el-cascader
							size="large"
							:options="options"
							:props="props"
							v-model="AreaArray"
							@change="selectArea"
							placeholder="选择您所在地址"
							clearable
						></el-cascader>
					</el-form-item>
					<el-form-item label="详细地址：" prop="detailAddress">
						<el-input
							v-model="ruleForm.detailAddress"
							placeholder="请输入详细地址"
						></el-input>
					</el-form-item>

					<el-form-item label="邮政编码：" prop="zipCode">
						<el-input
							v-model="ruleForm.zipCode"
							placeholder="请输入邮政编码"
						></el-input>
					</el-form-item>
					<el-form-item label="">
						<el-checkbox v-model="checked">设为默认地址</el-checkbox>
					</el-form-item>
					<el-form-item>
						<div class="operation flex">
							<div
								class="btn display pointer saveBtn"
								@click="submitForm('ruleForm')"
							>
								保存
							</div>
							<div
								class="btn display pointer cancleBtn"
								@click="addBtn('取消')"
							>
								取消
							</div>
						</div>
						<!-- <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
                        <el-button @click="resetForm('ruleForm')">重置</el-button> -->
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>
<script>
import areaJSON from '../../../http/area.json'
export default {
	props: {
		addressData: {},
		title: {},
	},
	data() {
		return {
			checked: true,
			options: areaJSON,
			props: {
				children: 'children',
				label: 'name',
				value: 'name',
			},
			AreaArray: [],
			ruleForm: {
				userName: '',
				userPhone: '',
				province: '',
				city: '',
				district: '',
				detailAddress: '',
				zipCode: '',
				addressState: '',
				token: '',
			},
			rules: {
				userName: [
					{ required: true, message: '请输入收货人名称', trigger: 'blur' },
				],
				userPhone: [
					{ required: true, message: '请输入11位手机号', trigger: 'blur' },
					{
						min: 11,
						max: 11,
						message: '请输入11位数的手机号码',
						trigger: 'blur',
					},
					// { pattern: /^1[3|5|7|8|9]\d{9}$/, message: '请输入正确的号码格式', trigger: 'change' }
				],
				province: [
					{ required: true, message: '请选择所在地址', trigger: 'blur' },
				],
				detailAddress: [
					{ required: true, message: '请输入详情地址', trigger: 'blur' },
				],
				zipCode: [
					// { required: true, message: '请输入邮政编码', trigger: 'blur' },
					{ required: false, message: '请输入6位邮政编码', trigger: 'blur' },
					{
						pattern: /^[1-9][0-9]{5}$/,
						message: '请输入正确格式邮政编码',
						trigger: 'change',
					},
				],
			},
		}
	},
	mounted() {
		if (this.title == '编辑') {
			this.ruleForm = this.addressData
			if (this.ruleForm.addressState == 1) {
				this.checked = true
			} else {
				this.checked = false
			}
			this.AreaArray = [
				this.ruleForm.province,
				this.ruleForm.city,
				this.ruleForm.district,
			]
		} else {
			this.ruleForm = {
				userName: '',
				userPhone: '',
				province: '',
				city: '',
				district: '',
				detailAddress: '',
				zipCode: '',
				addressState: '',
				token: '',
			}
		}
	},
	methods: {
		// 选择省市区
		selectArea(val) {
			this.ruleForm.province = val[0]
			this.ruleForm.city = val[1]
			this.ruleForm.district = val[2]
		},

		// 保存
		submitForm(formName) {
			this.ruleForm.token = this.$store.state.userData.token
			this.$refs[formName].validate((valid) => {
				if (valid) {
					if (this.checked == true) {
						this.ruleForm.addressState = 1
					} else {
						this.ruleForm.addressState = 2
					}

					if (this.title == '编辑') {
						// 修改地址
						this.modify()
					} else {
						//添加地址
						this.add()
					}
				} else {
					return false
				}
			})
		},

		//添加地址
		add() {
			this.api.insertAddress(this.ruleForm).then((res) => {
				if (res.code == 0) {
					this.$message.success('添加成功')
					this.$emit('submitForm')
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		//修改地址
		modify() {
			this.api.updateAddress(this.ruleForm).then((res) => {
				if (res.code == 0) {
					this.$message.success('修改成功')
					this.$emit('submitForm')
				} else {
					this.$message.error(res.msg)
				}
			})
		},

		addBtn(name) {
			this.$emit('addBtn', name)
		},
	},
}
</script>
<style lang="scss" scoped>
.address_box {
	.address_main {
		width: 724px;
		height: 519px;
		background: #ffffff;
		border-radius: 2px 2px 2px 2px;
		position: fixed;
		top: 50%;
		left: 50%;
		margin-top: -260px;
		margin-left: -362px;
		z-index: 99;
		.address_mai_head {
			width: 724px;
			height: 38px;
			background: #f4f4f4;
			padding-left: 30px;
			padding-right: 20px;
			.title {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
			}
			.el-icon-close {
				font-size: 24px;
				color: #8f8f8f;
			}
		}
		.formData_box {
			width: 100%;
			height: 481px;
			padding-top: 30px;
			padding-left: 53px;
			.el-input {
				width: 452px;
				height: 40px;
			}
			.el-cascader {
				width: 452px;
				height: 40px;
			}
			.el-form-item {
				margin-bottom: 20px;
			}

			.operation {
				.btn {
					width: 100px;
					height: 40px;
					border-radius: 3px;
					font-size: 18px;
				}
				.saveBtn {
					background: #e1251b;
					color: #ffffff;
					margin-right: 20px;
				}
				.cancleBtn {
					background: #f4f4f4;
					color: #333333;
				}
			}
		}
	}
	::v-deep .el-form-item__content {
		line-height: 0px;
	}
}
</style>