<template>
    <div class="step">
       <div class="article_steps_box Around" :class="steps== 3?'article_steps_boxs':'' ">
                <div class="list display  column lists" :class="steps == 2?'first_steps':''">
                    <div class="display num">1</div>
                    <div class="display label">我的购物车</div>
                </div>
                <div class="list display column" :class="
            steps == 2 || 
            steps == 3 ?'active':''">
                    <div class="display num">2</div>
                    <div class="display label">确认订单信息</div>
                </div>
                <div class="list display column" >
                    <div  class="display num">3</div>
                    <div  class="display label">支付订单</div>
                </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        steps:{
            type:Number
        }
    },
    data(){
        return{
        }
    }
}
</script>
<style lang="scss" scoped>
    .step{
        margin-top: 61px;
        margin-bottom: 64px;
        .article_steps_boxs{
            border-radius: 5px;
            background: #DB2D39 !important;
        }
       .article_steps_box{
                width: 1200px;
                height: 10px;
                background: #AAAAAA;
                border-radius: 5px;
                box-sizing: border-box;
               
                .first_steps{
                    border-radius: 5px 0px 0px 5px!important;
                }
                .lists{
                    background: #DB2D39;
                }
                .list{
                    width: 400px;
                    height: 10px;
                    border-radius:  5px;
                    .num{
                        width: 28px;
                        min-height: 28px;
                        background: #DB2D39;
                        border-radius: 50%;
                        color: #fff;
                        font-size: 14px;
                        margin-top: 25px;
                    }
                    .label{
                       margin-top: 9px;
                    }
                }
                .active{
                    background: #DB2D39;
                    border-radius: 0px 5px 5px 0px;
                }
            }
    }
</style>