<template>
	<div class="address_box flex-center">
		<div class="shipping_address">{{ title }}</div>
		<div class="add_address pointer" @click="addBtn(btnName)" v-if="btnName">
			+ {{ btnName }}
		</div>
	</div>
</template>
<script>
export default {
	props: {
		title: {
			type: String,
		},
		btnName: {
			type: String,
		},
	},
	data() {
		return {}
	},
	methods: {
		addBtn(name) {
			this.$emit('addBtn', name)
		},
	},
}
</script>
<style lang="scss" scoped>
.address_box {
	.shipping_address {
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
	}
	.add_address {
		font-size: 14px;
		color: #336699;
		margin-left: 34px;
	}
}
</style>