<template>
    <div class="placeOrder">
        <div class="cart_content">
            <Step :steps="2"></Step>
            <div class="orderDetails">
                <Title title="收货地址" btnName="新增地址" @addBtn="addBtn"></Title>
                <div class="address_boxs">
                    <div class="address_list Between pointer" v-for="(item, index) in addressList" :key="index"
                        @mouseenter="enter(index)" @mouseleave="leave()" @click="defaultBtn(item.id)">
                        <div class="address_list_fl flex-center">
                            <div class="consignee_name display pointer" :class="index == 0 ? 'selectedName' : ''">
                                {{ item.userName }}
                                <img class="selectImg" v-if="index == 0" src="@/assets/img/select.png" alt="" />
                            </div>
                            <div class="consignee_address">
                                {{
                                    item.province + item.city + item.district + item.detailAddress
                                }}
                            </div>
                            <div class="consignee_phone">{{ item.userPhone }}</div>
                        </div>
                        <div class="address_list_fr flex-center">
                            <div class="no_default flex-center" v-if="isShow == index">
                                <div class="default_address display" v-if="item.addressState == 1">
                                    默认地址
                                </div>
                                <div class="setDefault" v-else>设置默认地址</div>

                                <div class="compile" @click.stop="modifyBtn(item)">编辑</div>
                                <div class="delete" @click.stop="delBtn(item.id)">删除</div>
                            </div>
                        </div>
                    </div>
                    <div class="packUp flex-center pointer" @click="moreAddress">
                        {{ addressNum == 1 ? '更多地址' : '收起地址' }}
                        <img :src="addressNum == 1 ? down : upImgs" alt="" />
                    </div>
                </div>
                <div class="payType titleBox">
                    <Title title="支付方式"></Title>
                    <div class="payType_box">
                        <div class="payType_list flex">
                            <div class="list pointer display" :class="isPay == index ? 'payActive' : ''"
                                v-for="(item, index) in payData" :key="index" @click="payTypeBtn(item.id, index)">
                                {{ item.name }}
                                <img class="selectImg" v-if="isPay == index" src="@/assets/img/select.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="payType titleBox invoiceBox">
                    <Title title="发票信息" btnName="添加发票信息" @addBtn="addBtn('新增发票抬头')"></Title>
                    <div class="invoice flex">
                        <div class="open_invoice no_open display pointer"
                            :class="isOpenInvoice == 1 ? 'open_invoice_border' : ''" @click="isOpenInvoice = 1">
                            不开发票
                            <img class="selectImg" v-if="isOpenInvoice == 1" src="@/assets/img/select.png" alt="" />
                        </div>

                        <div class="invoice_list Between pointer" @click="isOpenInvoice = 2"
                            v-if="defaultInvoiceList.defaultState == 1"
                            :class="isOpenInvoice == 2 ? 'open_invoice_border' : ''">
                            <div class="companyName">
                                发票抬头：{{ defaultInvoiceList.invoiceHead }}
                            </div>
                            <div class="setBtn" @click="isOpen = true">设置</div>
                            <img class="selectImg" v-if="isOpenInvoice == 2" src="@/assets/img/select.png" alt="" />
                        </div>
                        <div class="open_invoice open display pointer" v-else @click="isOpen = true">
                            开发票
                        </div>
                    </div>
                </div>
                <div class="goodsBox">
                    <Title title="商品信息"></Title>
                    <div class="goods">
                        <div class="goods_table">
                            <div class="table_head flex-center">
                                <div class="table_head1">商品</div>
                                <div class="table_head2">单价(元)</div>
                                <div class="table_head3">数量</div>
                                <div class="table_head4">小计</div>
                            </div>
                            <div class="goods_content">
                                <div class="goodsList flex" v-for="(item, index) in goodData.confirmItemOrderVOS"
                                    :key="index">
                                    <div class="goods_name_img flex">
                                        <img class="goods_img" :src="item.goodsImgUrl" alt="" />
                                        <div class="name_specification">
                                            <div class="goods_name row">{{ item.goodsName }}</div>
                                            <div class="goods_specification">{{ item.skuTitle }}</div>
                                        </div>
                                    </div>
                                    <div class="goods_price display">
                                        ￥{{ Number(item.unitPrice).toFixed(2) }}
                                    </div>
                                    <div class="goods_num display">{{ item.goodsNum }}</div>
                                    <div class="total_price display">
                                        ￥{{ Number(item.totalPrice).toFixed(2) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="coupons_box">
                    <Title title="优惠券"></Title>
                    <div class="coupons flex" v-if="couponsData.length != 0">
                        <div @click="selectedInvoice(item, index)" class="coupons_list pointer"
                            v-for="(item, index) in couponsData" :key="index">
                            <div class="coupons_top flex-center column" :style="{
                                background: `url(${dateTime >= new Date(item.startDate).getTime() &&
                                        goodData.sumGoodsPrice >= item.consumptionPrice
                                        ? coupons_y
                                        : coupons_n
                                    }) center no-repeat`,
                                'background-size': 'cover',
                            }">
                                <div class="coupons_price flex">
                                    <div class="icon_price">￥</div>
                                    <div class="price">{{ item.couponMoney }}</div>
                                </div>
                                <div class="full_reduction">
                                    满{{ item.consumptionPrice }}可用
                                </div>
                                <div class="coupons_time">
                                    {{ item.startDate }}至{{ item.overDate }}
                                </div>
                            </div>
                            <div class="coupons_bottom display">全部商品都适用</div>
                            <img class="selectUp" v-if="item.isChcked == true" src="@/assets/img/selectUp.png" alt="" />
                        </div>
                    </div>
                    <div v-else class="no_coupon pointer">
                        <span class="no_coupon1">您暂时没有优惠券</span>---><span class="no_coupon2" @click="toCoupon">去领劵</span>
                    </div>
                </div>
                <div class="integral_box">
                    <div class="flex-center">
                        <Title title="积分抵扣"></Title>
                        <div class="jifen">剩余积分{{ goodData.integral }}</div>
                    </div>
                    <div class="integral flex-center pointer" @click="choose()">
                        <img class="isCheked" v-if="isChcked == false" src="@/assets/img/isCheked_n.png" alt="" />
                        <img class="isCheked" v-else src="@/assets/img/isCheked_y.png" alt="" />
                        <div class="integral_num">
                            使用{{ params.integral }}积分抵扣：{{
                                PointsDeduction.toFixed(2)
                            }}元
                        </div>
                        <div class="integral_price">¥-{{ PointsDeduction.toFixed(2) }}</div>
                    </div>
                </div>
                <div class="leave_msg_box">
                    <Title title="订单留言"></Title>
                    <div class="leave_msg">
                        <textarea name="" v-model="buyerWord" placeholder="若您对订单有特殊要求，请告知我们，我们客服会第一时间跟您沟通，尽量满足您的要求。" id=""
                            cols="30" rows="10"></textarea>
                    </div>
                </div>
                <div class="details_box flex">
                    <div>
                        <div class="list flex">
                            <div class="label">商品总件数：</div>
                            <div class="price">{{ goodsTotalNum }}件</div>
                        </div>
                        <div class="list flex">
                            <div class="label">总商品金额：</div>
                            <div class="price">
                                ￥{{ Number(goodData.sumGoodsPrice).toFixed(2) }}
                            </div>
                        </div>
                        <div class="list flex">
                            <div class="label">优惠券抵用：</div>
                            <div class="price">￥ -{{ Number(couponMoney).toFixed(2) }}</div>
                        </div>
                        <div class="list flex">
                            <div class="label">积分抵用：</div>
                            <div class="price">
                                ￥-{{ Number(PointsDeduction).toFixed(2) }}
                            </div>
                        </div>
                        <div class="list flex">
                            <div class="label">运费：</div>
                            <div class="price">
                                ￥{{ Number(goodData.expressFee).toFixed(2) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="total_price_box flex">
                    <div>
                        <div class="total_price flex-center">
                            <div class="cope_with">应付总额：</div>
                            <div class="price">￥{{ Number(totalPrice).toFixed(2) }}</div>
                        </div>
                        <div class="receives_address">
                            寄送至：
                            {{
                                defaultAddress.province +
                                defaultAddress.city +
                                defaultAddress.district +
                                defaultAddress.detailAddress
                            }}
                            收货人：{{ defaultAddress.userName }}
                            {{ defaultAddress.userPhone }}
                        </div>
                    </div>
                </div>
                <div class="submit_order flex">
                    <div class="btn display pointer" @click="submitBtn" v-if="this.$store.state.platformInfo.isPay == 0">
                        提交订单
                    </div>
                </div>
            </div>
        </div>
        <div v-if="typeName == '新增地址'">
            <Address :title="title" :addressData="addressData" @addBtn="addBtn" @submitForm="submitForm" />
        </div>
        <div v-if="typeName == '新增发票抬头'">
            <Invoice :title="title" :invoiceData="invoiceData" :invoiceList="invoiceList" @saveBtn="saveBtn"
                @addBtn="addBtn" />
        </div>
        <div class="invoice_box" v-if="isOpen == true">
            <div class="mask"></div>
            <div class="invoice_details">
                <div class="invoice_head Between">
                    <div class="title">选择发票抬头</div>
                    <i class="el-icon-close display pointer" @click="isOpen = false"></i>
                </div>
                <div class="invoice_form">
                    <el-table :data="invoiceList" style="width: 100%" row-key="id" lazy max-height="250"
                        @row-click="changeBtn">
                        <el-table-column prop="invoiceHead" label="发票抬头" align="center">
                        </el-table-column>
                        <el-table-column prop="name" label="发票类型" align="center">
                            <template slot-scope="scope">
                                <div v-if="scope.row.invoiceType == 1">个人发票</div>
                                <div v-if="scope.row.invoiceType == 2">企业发票</div>
                                <div v-if="scope.row.invoiceType == 3">增值税专用发票</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="address" label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button size="small" @click="modifyInvoiceBtn(scope.row)">修改</el-button>
                                <el-button size="small" @click="delInvoiceBtn(scope.row.id)">删除</el-button>
                                <el-button v-if="scope.row.defaultState == 1" type="primary" size="small">默认发票</el-button>
                                <el-button v-if="scope.row.defaultState == 2" size="small">设置默认</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Step from './components/step.vue'
import Title from './components/title.vue'
import Invoice from './components/invoice.vue'
import Address from './components/address.vue'
export default {
    components: {
        Step,
        Title,
        Invoice,
        Address,
    },
    data() {
        return {
            isChcked: false,
            isOpenInvoice: 0,
            isOpen: false,
            title: '',
            invoiceData: {},
            addressData: {},
            typeName: '',
            upImgs: require('@/assets/img/goodsDetails/upImgs.png'),
            down: require('@/assets/img/goodsDetails/down.png'),
            coupons_y: require('@/assets/img/coupons_y.png'),
            coupons_n: require('@/assets/img/coupons_n.png'),
            isShow: null,
            isPay: 0,
            payData: [
                {
                    id: 1,
                    name: '在线支付',
                },
                {
                    id: 2,
                    name: '线下打款',
                },
            ],
            goodData: {},
            goodsTotalNum: 0,
            addressList: [],
            addressNum: 1,
            defaultAddress: {}, //默认地址
            buyerWord: '', //备注
            couponsData: [],
            params: {
                invoiceId: '',
                couponId: '',
                userAddressId: '',
                integral: 0,
            },
            orderType: '',
            invoiceList: [],
            defaultInvoiceList: {},
            selectedInvoiceId: null, //选中优惠券id
            couponMoney: 0, //选中优惠券价格
            totalPrice: 0, //总价格
            recordPrices: 0, //记录价格
            integralMoney: 0, //积分抵扣金额
            algorithm: 0, //积分抵扣金额算法
            PointsDeduction: 0,
            dateTime: '',
        }
    },
    mounted() {
        if (this.$store.state.userData.token) {
            this.params = JSON.parse(sessionStorage.getItem('confirmOrder')) || {}
            this.params.token = this.$store.state.userData.token
            this.orderType = this.$route.query.type

            if (this.orderType == 'goods') {
                this.getOrder()
            } else {
                this.getCarOrder()
            }
            //获取地址列表
            this.getAddress()
            //获取发票列表
            this.getInvoiceData()
            //获取优惠券列表
            this.getCoupon()
            // 当前时间时间戳
            this.dateTime = new Date().getTime()
        }
    },
    methods: {
        // 获取商品订单详情
        getOrder() {
            let data = {
                token: this.$store.state.userData.token,
                goodsDetailsConfirmOrderItemParamDTOS:
                    this.params.goodsDetailsConfirmOrderItemParamDTOS,
            }
            this.api.goodsDetailsConfirmOrder(data).then((res) => {
                if (res.code == 0) {
                    this.goodData = res.data
                    this.integralMoney = res.data.integralMoney

                    this.algorithm = res.data.integralRatio
                    this.goodData.confirmItemOrderVOS.forEach((item) => {
                        this.goodsTotalNum += item.goodsNum
                    })
                    this.recordPrices = this.goodData.sumSalePrice
                    this.totalPrice = this.goodData.sumSalePrice
                }
            })
        },
        // 获取购物车商品订单详情
        getCarOrder() {
            this.api.shopCartConfirmOrder(this.params).then((res) => {
                if (res.code == 0) {
                    this.goodData = res.data
                    this.integralMoney = res.data.integralMoney

                    this.algorithm = res.data.integralRatio
                    this.goodData.confirmItemOrderVOS.forEach((item) => {
                        this.goodsTotalNum += item.goodsNum
                    })
                    this.recordPrices = this.goodData.sumSalePrice
                    this.totalPrice = this.goodData.sumSalePrice
                }
            })
        },
        //添加地址
        addBtn(name) {
            this.typeName = name
            this.title = name
        },
        //保存地址
        submitForm() {
            this.typeName = ''
            this.getAddress()
        },
        // 修改地址
        modifyBtn(item) {
            this.addressData = item
            this.title = '编辑'
            this.typeName = '新增地址'
        },
        // 修改默认地址
        defaultBtn(id) {
            let params = {
                id: id,
                token: this.$store.state.userData.token,
            }
            this.api.updateState(params).then((res) => {
                if (res.code == 0) {
                    this.getAddress()
                } else {
                    this.$message.error(res.msg)
                }
            })
        },

        //删除地址
        delBtn(id) {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let params = {
                        id: id,
                        token: this.$store.state.userData.token,
                    }
                    this.api.deleteAddress(params).then((res) => {
                        if (res.code == 0) {
                            this.typeName = ''
                            this.$message.success('删除成功')
                            this.getAddress()
                        }
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        //获取地址
        getAddress() {
            let params = {
                page: 1,
                limit: this.addressNum,
                token: this.$store.state.userData.token,
            }
            this.api.findByUserIdAddress(params).then((res) => {
                res.data.forEach((item) => {
                    item.userPhone =
                        item.userPhone.substr(0, 3) + '****' + item.userPhone.substr(7)
                    if (item.addressState == 1) {
                        this.defaultAddress = item
                    } else {
                        this.defaultAddress = res.data[0]
                    }
                })
                this.addressList = res.data
            })
        },

        // 保存发票
        saveBtn() {
            this.typeName = ''
            this.getInvoiceData()
        },
        //获取发票信息
        getInvoiceData() {
            this.api
                .invoiceList({ token: this.$store.state.userData.token })
                .then((res) => {
                    if (res.code == 0) {
                        let obj = {}
                        res.data.forEach((item) => {
                            if (item.defaultState == 1) {
                                obj = item
                            }
                            this.defaultInvoiceList = obj
                            this.params.invoiceId = this.defaultInvoiceList.id
                            this.isOpenInvoice = 2
                        })

                        this.invoiceList = res.data
                    }
                })
        },
        // 鼠标移入
        enter(index) {
            this.isShow = index
        },
        // 鼠标移出
        leave() {
            this.isShow = null
        },
        // 点击更多地址
        moreAddress() {
            if (this.addressNum == 1) {
                this.addressNum = 10
            } else {
                this.addressNum = 1
            }
            this.getAddress()
        },
        //点击支付方式
        payTypeBtn(id, index) {
            this.isPay = index
        },
        submitBtn() {
            if (this.isOpenInvoice == 1) {
                this.params.invoiceId = ''
            }

            this.params.userAddressId = this.defaultAddress.id
            this.params.goodsDetailsOrderItemParamDTOS =
                this.params.goodsDetailsConfirmOrderItemParamDTOS
            this.params.buyerWord = this.buyerWord

            if (this.orderType == 'goods') {
                this.api.addGoodsDetailsOrder(this.params).then((res) => {
                    if (res.code == 0) {
                        this.$router.push({
                            path: '/cart/orderPay',
                            query: {
                                orderNum: res.data,
                                price: this.totalPrice,
                            },
                        })
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            } else {
                this.api.addShopCartOrder(this.params).then((res) => {
                    if (res.code == 0) {
                        this.$router.push({
                            path: '/cart/orderPay',
                            query: {
                                orderNum: res.data,
                                price: this.totalPrice,
                            },
                        })
                        this.$message.success('成功！')
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }
        },
        //删除发票
        delInvoiceBtn(id) {
            let params = {
                id: id,
                token: this.$store.state.userData.token,
            }
            this.api.deleteInvoice(params).then((res) => {
                if (res.code == 0) {
                    this.getInvoiceData()
                    this.$message.success('删除成功')
                } else {
                    this.$message
                }
            })
        },
        //设置默认发票
        changeBtn(row) {
            let params = {
                id: row.id,
                token: this.$store.state.userData.token,
            }
            this.api.invoiceUpdateState(params).then((res) => {
                if (res.code == 0) {
                    this.getInvoiceData()
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //修改发票
        modifyInvoiceBtn(item) {
            this.isOpen = true
            this.typeName = '新增发票抬头'
            this.title = '修改发票'
            this.invoiceData = item
        },
        // 时间格式转化
        recentTime(day, fmt, time) {
            //获取当前时间的毫秒值
            let now = (time ? new Date(time) : new Date()).getTime()
            // 获取前后n天时间
            let recent = new Date(now + day * 24 * 60 * 60 * 1000)
            // key：正则匹配表达式，value：对应的时间、日期
            let fmtObj = {
                'M+': recent.getMonth() + 1, //月份
                'd+': recent.getDate(), //日
                'h+': recent.getHours(), //时
                'm+': recent.getMinutes(), //分
                's+': recent.getSeconds(), //秒
            }
            // 获取匹配年份替换
            if (/(y+)/.test(fmt)) {
                //RegExp.$1 匹配结果，替换成对应的长度。如：yyyy就替换成整个年份2021，yy就替换成后两位21，以此类推
                fmt = fmt.replace(
                    RegExp.$1,
                    (recent.getFullYear() + '').substr(4 - RegExp.$1.length)
                )
            }
            for (let key in fmtObj) {
                if (new RegExp(`(${key})`).test(fmt)) {
                    //日期，时、分、秒替换，判断fmt是否需要补0，如：yyyy-M-d h:m:s 不补0,yyyy-MM-dd hh:mm:ss 则自动补0
                    fmt = fmt.replace(
                        RegExp.$1,
                        RegExp.$1.length == 1
                            ? fmtObj[key]
                            : ('00' + fmtObj[key]).substr(('' + fmtObj[key]).length)
                    )
                }
            }
            return fmt
        },
        //获取优惠券列表
        getCoupon() {
            this.api
                .queryByUserId({ token: this.$store.state.userData.token, status: 0 })
                .then((res) => {
                    if (res.code == 0) {
                        let coupon = JSON.parse(JSON.stringify(res.data))
                        // 订单总金额
                        let sumPrice = this.goodData
                        // 符合优惠券金额
                        let couponPriceArr = []
                        // 不符合优惠券金额
                        let NoCouponPriceArr = []
                        coupon.forEach((item, index) => {
                            if (item.takeEffect == 2) {
                                item.startDate = item.gmtCreate.substring(0, 10)
                                item.overDate = this.recentTime(
                                    item.validDay,
                                    'yyyy-MM-dd',
                                    item.gmtCreate
                                )
                            }
                            if (item.consumptionPrice <= sumPrice.sumGoodsPrice) {
                                // 符合优惠券金额
                                couponPriceArr.push(item)
                            } else {
                                // 不符合
                                NoCouponPriceArr.push(item)
                            }
                            this.$set(item, 'isChcked', false)
                        })
                        // let couponsData = couponPriceArr.concat(NoCouponPriceArr)
                        this.couponsData = [...couponPriceArr, ...NoCouponPriceArr]
                    }
                })
        },
        //选择优惠券
        selectedInvoice(item) {
            // 优惠劵开始时间时间戳
            let dataTime = new Date(item.startDate).getTime()
            this.totalPrice = this.recordPrices
            if (this.dateTime < dataTime) {
                this.totalPrice = this.totalPrice - this.couponMoney
                return this.$message.error('暂未到优惠劵使用日期')
            }
            if (this.totalPrice < item.consumptionPrice) {
                this.totalPrice = this.totalPrice - this.couponMoney
                return this.$message.error(
                    '商品价格满' + item.consumptionPrice + '元可用'
                )
            }
            this.couponsData.forEach((rews) => {
                if (rews.id === item.id) {
                    item.isChcked = !item.isChcked
                    if (item.isChcked == true) {
                        //选中优惠券
                        this.couponMoney = rews.couponMoney
                        this.totalPrice = this.totalPrice - rews.couponMoney
                        this.params.couponId = rews.id
                        //选择了积分抵扣
                        if (this.isChcked == true) {
                            if (this.totalPrice / 2 > this.goodData.integralMoney) {
                                this.totalPrice = this.totalPrice - this.goodData.integralMoney
                            } else {
                                let integerRrice = parseInt(this.totalPrice / 2)
                                this.params.integral =
                                    (integerRrice * this.goodData.integral) /
                                    (this.goodData.integral * this.goodData.integralRatio)
                                this.PointsDeduction = integerRrice
                                this.totalPrice = this.totalPrice - integerRrice
                            }
                        }
                    } else {
                        //取消选中优惠券
                        if (this.isChcked == true) {
                            //选择了积分抵扣
                            if (this.totalPrice / 2 > this.goodData.integralMoney) {
                                this.totalPrice +=
                                    this.couponMoney + this.goodData.integralMoney
                                // this.params.integral = this.goodData.integral
                                this.params.integral =
                                    this.goodData.integral /
                                    (this.goodData.integral * this.goodData.integralRatio)
                            } else {
                                let integerRrice = parseInt(this.totalPrice / 2)
                                this.PointsDeduction = integerRrice
                                //需要抵扣的积分
                                this.params.integral =
                                    (integerRrice * this.goodData.integral) /
                                    (this.goodData.integral * this.goodData.integralRatio)
                            }
                        }
                        this.totalPrice = this.recordPrices - this.PointsDeduction
                        this.couponMoney = 0
                        this.params.couponId = ''
                    }
                } else {
                    rews.isChcked = false
                }
            })
        },
        //领取优惠券
        toCoupon() {
            this.$router.push('/plaza/index')
        },
        //点击积分
        choose() {
            if (this.goodData.integral != 0 && this.goodData.integralMoney >= 1) {
                this.isChcked = !this.isChcked
                this.integralCalculation()
            } else {
                this.$message.error('抵扣积分不可小于1000')
            }
        },
        integralCalculation() {
            if (this.isChcked == true) {
                if (this.totalPrice / 2 > this.goodData.integralMoney) {
                    this.totalPrice = this.totalPrice - this.goodData.integralMoney
                    this.PointsDeduction = this.goodData.integralMoney

                    this.params.integral =
                        (this.goodData.integral /
                            (this.goodData.integral * this.goodData.integralRatio)) *
                        this.goodData.integralMoney
                } else {
                    //需要抵扣的积分
                    // let total = this.goodData.integral*this.goodData.integralRatio
                    let integerRrice = parseInt(this.totalPrice / 2)
                    this.params.integral =
                        (integerRrice * this.goodData.integral) /
                        (this.goodData.integral * this.goodData.integralRatio)
                    this.PointsDeduction = integerRrice
                    this.totalPrice = this.totalPrice - integerRrice
                }
            } else {
                this.params.integral = 0
                this.PointsDeduction = 0
                this.totalPrice = this.recordPrices - this.couponMoney
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.cart_content {
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 30px;

    .orderDetails {
        background: white;
        padding: 30px 44px 30px 20px;
        box-sizing: border-box;

        .titleBox {
            margin-top: 21px;
        }

        .address_boxs {
            width: 100%;
            border-bottom: 1px solid #eaeaea;
            margin-top: 21px;
            padding-left: 30px;
            padding-bottom: 24px;
            box-sizing: border-box;

            .address_list:hover {
                background: #f4f4f4;
            }

            .address_list {
                width: 100%;
                height: 30px;
                box-sizing: border-box;
                font-weight: 400;
                color: #333333;
                padding-right: 16px;
                margin-bottom: 10px;

                .address_list_fl {
                    .selectedName {
                        border: 2px solid #e1251b !important;
                    }

                    .consignee_name {
                        width: 135px;
                        height: 30px;
                        background: #ffffff;
                        border: 1px solid #d0d0d0;
                        margin-right: 15px;
                        position: relative;
                    }

                    .consignee_address {
                        margin-right: 15px;
                    }

                    .consignee_phone {}
                }

                .address_list_fr {
                    font-size: 12px;
                    color: #336699;

                    .defaults {
                        .compile {
                            color: #333 !important;
                            margin: 0 !important;
                        }
                    }

                    .default_address {
                        width: 64px;
                        height: 22px;
                        background: #888888;
                        border-radius: 3px;
                        color: #ffffff;
                    }

                    .setDefault {}

                    .compile {
                        margin: 0 30px;
                    }

                    .delete {}
                }
            }

            .packUp {
                font-size: 12px;
                color: #666666;
                margin-top: 21px;

                img {
                    width: 13px;
                    height: 7px;
                    margin-left: 5px;
                }
            }
        }

        .payType {
            border-bottom: 1px solid #eaeaea;

            .payType_box {
                .payType_list {
                    padding-left: 30px;
                    padding-bottom: 37px;
                    box-sizing: border-box;

                    .list {
                        width: 104px;
                        height: 30px;
                        background: #ffffff;
                        border: 1px solid #d0d0d0;
                        font-size: 12px;
                        color: #333333;
                        margin-top: 20px;
                        margin-right: 20px;
                        position: relative;
                    }

                    .payActive {
                        border: 1px solid #e1251b;
                    }
                }
            }
        }

        .invoice {
            margin-top: 20px;
            padding-bottom: 27px;

            .open_invoice {
                width: 104px;
                height: 30px;
                background: #ffffff;
                border: 1px solid #d0d0d0;
                font-size: 12px;
                color: #333333;
                margin-left: 30px;
                margin-right: 14px;
                position: relative;
            }

            .open_invoice_border {
                border: 1px solid #e1251b !important;
            }

            .invoice_list {
                width: 318px;
                height: 30px;
                background: #ffffff;
                border: 1px solid #d0d0d0;
                position: relative;
                padding-left: 15px;
                padding-right: 26px;
                box-sizing: border-box;

                .companyName {
                    font-size: 12px;
                    color: #666666;
                }

                .setBtn {
                    font-size: 14px;
                    color: #336699;
                }
            }
        }

        .selectImg {
            width: 16px;
            height: 18px;
            position: absolute;
            right: -1px;
            bottom: -1px;
        }

        .goodsBox {
            margin-top: 20px;

            .goods {
                margin-top: 22px;

                .table_head {
                    height: 40px;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    background: #f4f4f4;
                    color: #666666;

                    .table_head1 {
                        margin-left: 125px;
                    }

                    .table_head2 {
                        margin-left: 400px;
                    }

                    .table_head3 {
                        margin-left: 135px;
                    }

                    .table_head4 {
                        margin-left: 237px;
                    }
                }

                .goods_content {
                    .goodsList {
                        border: 1px solid #f5f5f5;
                        border-top: none;
                        padding: 22px 35px 22px 24px;
                        box-sizing: border-box;

                        .goods_name_img {
                            .goods_img {
                                width: 78px;
                                height: 78px;
                            }

                            .name_specification {
                                margin-left: 24px;

                                .goods_name {
                                    width: 334px;
                                    font-size: 12px;
                                    color: #333333;
                                }

                                .goods_specification {
                                    font-size: 12px;
                                    font-family: Microsoft YaHei;
                                    margin-top: 20px;
                                    line-height: 20px;
                                }
                            }
                        }

                        .goods_price {
                            width: 100px;
                            height: 12px;
                            margin-top: 8px;
                            margin-left: 60px;
                        }

                        .goods_num {
                            width: 100px;
                            height: 12px;
                            margin-top: 8px;
                            margin-left: 70px;
                        }

                        .total_price {
                            width: 100px;
                            height: 12px;
                            margin-top: 8px;
                            margin-left: 155px;
                        }
                    }
                }
            }
        }

        .coupons_box {
            margin-top: 24px;

            .no_coupon {
                font-size: 14px;
                color: #336699;
                margin-left: 10px;
                margin-top: 20px;
                margin-left: 46px;

                .no_coupon1 {
                    color: #666464;
                }

                .no_coupon2 {
                    color: red;
                    cursor: pointer;
                }
            }

            .coupons {
                // width: 95%;
                max-height: 450px;
                overflow: hidden;
                overflow-y: scroll;
                margin-top: 15px;
                margin-left: 50px;
                border-bottom: 1px solid #f5f5f5;
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;

                .coupons_list {
                    width: 210px;
                    height: 170px;
                    border: 1px solid #fff;
                    position: relative;
                    margin-right: 3px;

                    .selectUp {
                        width: 56px;
                        height: 44px;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    .coupons_top {
                        width: 209px;
                        height: 122px;

                        .coupons_price {
                            // font-size: 27px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #ffffff;
                            margin-top: 25px;
                            display: flex;
                            align-items: flex-end;

                            .icon_price {
                                margin-top: 2px;
                            }

                            .price {
                                font-size: 27px;
                                line-height: 31px;
                            }
                        }

                        .full_reduction {
                            font-size: 14px;
                            color: #ffffff;
                            line-height: 18px;
                            margin-top: 6px;
                        }

                        .coupons_time {
                            font-size: 12px;
                            margin-top: 12px;
                            color: #ffffff;
                            line-height: 8px;
                        }
                    }

                    .coupons_bottom {
                        font-size: 16px;
                        color: #666666;
                        height: 47px;
                        background: #f9f9f9;
                    }
                }
            }
        }

        .integral_box {
            margin-top: 68px;

            .jifen {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #e1251b;
                margin-left: 20px;
            }

            .integral {
                margin-top: 25px;
                margin-left: 46px;

                .isCheked {
                    width: 18px;
                    height: 18px;
                    border-radius: 5px;
                }

                .integral_num {
                    font-size: 14px;
                    color: #333333;
                    margin-left: 10px;
                }

                .integral_price {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #e1251b;
                    margin-left: 50px;
                }
            }
        }

        .leave_msg_box {
            margin-top: 47px;

            .leave_msg {
                textarea {
                    width: 1108px;
                    height: 71px;
                    border: 1px solid #eaeaea;
                    padding: 9px 21px;
                    box-sizing: border-box;
                    margin-top: 19px;
                    margin-left: 28px;
                }
            }
        }

        .details_box {
            margin-top: 22px;
            justify-content: flex-end;

            .list {
                margin-bottom: 12px;

                .label {
                    width: 80px;
                    font-size: 12px;
                    color: #333333;
                    text-align: right;
                    margin-right: 57px;
                }

                .price {
                    font-size: 12px;
                    color: #333333;
                    margin-right: 24px;
                }
            }
        }

        .total_price_box {
            width: 1106px;
            height: 65px;
            background: #f4f4f4;
            justify-content: flex-end;
            margin-left: 32px;
            padding: 12px 30px;
            box-sizing: border-box;

            .total_price {
                justify-content: flex-end;

                .cope_with {
                    font-size: 12px;
                    color: #666666;
                }

                .price {
                    font-size: 24px;
                    color: #e33a30;
                    font-family: Microsoft YaHei;
                    font-weight: 500;
                    line-height: 0;
                }
            }

            .receives_address {
                font-size: 12px;
                color: #666666;
                margin-top: 8px;
            }
        }

        .submit_order {
            justify-content: flex-end;
            margin-top: 20px;

            .btn {
                width: 160px;
                height: 56px;
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #ffffff;
                background: #e33a30;
            }
        }
    }
}

.invoice_box {
    .invoice_details {
        width: 800px;
        background: #fff;
        position: fixed;
        top: 50%;
        left: 50%;
        margin-left: -400px;
        margin-top: -200px;
        z-index: 10;

        .invoice_head {
            width: 800px;
            height: 38px;
            background: #f4f4f4;
            padding-left: 30px;
            padding-right: 20px;

            .title {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
            }

            .el-icon-close {
                font-size: 24px;
                color: #8f8f8f;
            }
        }

        .invoice_form {
            padding: 22px 28px;
            box-sizing: border-box;
        }
    }
}

::v-deep .el-table th.el-table__cell.is-leaf {
    background: #f4f4f4;
}

::v-deep .el-table tr td {
    cursor: pointer !important;
}
</style>